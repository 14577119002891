const RenzoIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" fill="#fff">
			<g id="renzo">
				<path d="M421,265.4v1c-7.4,81.7-72.3,145.6-151.7,153.7h-1.7l153.4-154.7Z" />
				<path d="M325.6,97.4c-4.3-2-8.7-4-13-5.7-8.7-3.3-17.7-6-27.1-8-11.5-2.4-22.8-3.7-34.9-3.7-5.7,0-11.4.3-17.1.7-39.5,4-74.7,21.1-101.8,47.2-1.7,1.7-3.7,3.7-5.4,5.4-25.8,27.1-42.5,62.9-46.2,102.1-.3,5-.7,10-.7,15.4,0,12.7,1.3,25.1,4,37.2,2,9,4.7,17.7,8,26.4,2,4.7,4,9.4,6,13.7,4,7.7,8.4,15.1,13.4,22.1l28.1-28.5,180.8-182.8,28.1-28.5c-6.5-4.6-14.2-9-22.2-13ZM122.7,283.2c-.3-1-.7-2.3-.7-3.3-2-9.4-3-19.1-3-28.8,0-33.8,12.7-65.6,35.8-90.1l4.7-4.7c24.8-23.8,56.9-36.8,91.4-36.8,9,0,18.1,1,26.8,2.7,1.3.3,2.7.7,4,1l-159,160Z" />
				<path d="M191.7,411.7c-4.4-1.7-8.4-3.3-12.4-5-8.4-4-16.4-8.4-24.1-13.7l236.4-238.7c5,7.4,9.7,15.1,13.4,23.4,2,4.4,4,8.7,5.7,13.1l-219,220.9Z" />
			</g>
		</svg>
	)
}

export default RenzoIcon
