interface Props {
	width?: number | `${string}%`
	height?: number | `${string}%`
}
const DiscordIcon = ({ width = 31, height = 24 }: Props) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 31 24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M25.4155 2.28516C23.4702 1.37109 21.396 0.714844 19.228 0.339844C18.9585 0.820312 18.6538 1.46484 18.4311 1.98047C16.1225 1.62891 13.8374 1.62891 11.5757 1.98047C11.3647 1.46484 11.0366 0.820312 10.7788 0.339844C8.59911 0.714844 6.51317 1.38281 4.56786 2.28516C0.653793 8.20312 -0.400894 13.9805 0.12645 19.6758C2.72801 21.6211 5.24754 22.793 7.7202 23.5664C8.32957 22.7227 8.88036 21.832 9.34911 20.8945C8.45848 20.5547 7.60301 20.1328 6.79442 19.6523C7.00536 19.4883 7.21629 19.3242 7.42723 19.1602C12.3608 21.4688 17.728 21.4688 22.603 19.1602C22.8139 19.3359 23.0132 19.5 23.2358 19.6523C22.4272 20.1445 21.56 20.5664 20.6694 20.9062C21.1382 21.8438 21.6772 22.7461 22.2983 23.5781C24.771 22.8047 27.2905 21.6328 29.8921 19.6875C30.4897 13.0781 28.8022 7.35937 25.4155 2.28516ZM10.0171 16.1719C8.54051 16.1719 7.32176 14.7891 7.32176 13.1016C7.32176 11.4141 8.50536 10.0312 10.0171 10.0312C11.5288 10.0312 12.7358 11.4141 12.7124 13.1016C12.7124 14.7891 11.5171 16.1719 10.0171 16.1719ZM19.978 16.1719C18.5014 16.1719 17.2827 14.7891 17.2827 13.1016C17.2827 11.4141 18.4663 10.0312 19.978 10.0312C21.4897 10.0312 22.6968 11.4141 22.6733 13.1016C22.6733 14.7891 21.4897 16.1719 19.978 16.1719Z" />
		</svg>
	)
}

export default DiscordIcon
