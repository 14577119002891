import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Hex } from 'viem'

import { CalculatePoints } from '@services/DepositService'
import { getTransactions } from '@services/ExchangeRateService'
import BigNumber from 'bignumber.js'
import { AppState } from './Store'

const unixTime = Math.floor(new Date().getTime() / 1000)

export interface UserState {
	depositPending: boolean
	depositError: boolean
	points?: number
	amount?: number
	isConnected: boolean
	address?: Hex
	campaign?: boolean
	preferredProtocol?: number

	akkoPoints?: number
	kelpPoints?: number
	etherfiPoints?: number
	renzoPoints?: number
	swellPoints?: number
	eigenlayerPoints?: number
}

const initialState: UserState = {
	depositPending: false,
	depositError: false,
	isConnected: false
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsConnectedState(state, { payload }: PayloadAction<boolean>) {
			state.isConnected = payload
		},
		setAddressState(state, { payload }) {
			state.address = payload
		},
		setPreferredProtocolState(state, { payload }: PayloadAction<number>) {
			state.preferredProtocol = payload
			state.campaign = true
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDepositData.pending, (state) => {
				state.depositPending = true
			})
			.addCase(getDepositData.fulfilled, (state, { payload }) => {
				const points = payload.reduce(
					(prev, { amount, blockTimestamp }) =>
						prev +
						(BigInt(amount) * (BigInt(unixTime) - BigInt(blockTimestamp))) /
							BigInt(3600),
					0n
				)

				const amount = payload.reduce(
					(prev, { amount }) => prev + BigInt(amount),
					0n
				)

				state.depositPending = false
				state.depositError = false
				state.preferredProtocol = payload[0].preferredProtocol ?? 0
				state.points = Number(
					new BigNumber(Number(points)).div(1000000000000000000)
				)
				state.amount = Number(
					new BigNumber(Number(amount)).div(1000000000000000000)
				)
			})
			.addCase(getDepositData.rejected, (state) => {
				state.depositPending = false
				state.depositError = true
			})

			// Transactions
			.addCase(getTransactionData.pending, (state) => {
				state.depositPending = true
			})
			.addCase(getTransactionData.fulfilled, (state, { payload }) => {
				state.akkoPoints = payload.akkoPoints
				state.kelpPoints = payload.kelpPoints
				state.etherfiPoints = payload.etherfiPoints
				state.renzoPoints = payload.renzoPoints
				state.swellPoints = payload.swellPoints
				state.eigenlayerPoints = payload.eigenlayerPoints

				state.depositPending = false
				state.depositError = false
			})
			.addCase(getTransactionData.rejected, (state) => {
				state.depositPending = false
				state.depositError = true
			})
	}
})

export const getDepositData = createAsyncThunk(
	'user/deposit',
	async (address: Hex) => (await CalculatePoints(address)).deposits
)

export const getTransactionData = createAsyncThunk(
	'exchange/transactions',
	async (address: Hex) => await getTransactions(address)
)

export const getPointsState = (state: AppState): number =>
	state.user?.points ?? 0

export const getAmountState = (state: AppState): number | undefined =>
	state.user?.amount

export const isConnectedState = (state: AppState): boolean =>
	state.user?.isConnected

export const getAddressState = (state: AppState): Hex | undefined =>
	state.user?.address

export const getCampaignState = (state: AppState): boolean =>
	state.user?.campaign ?? false

export const getPreferredProtocolState = (state: AppState): number =>
	state.user?.preferredProtocol ?? 0

export const getAkkoState = (state: AppState): number =>
	state.user.akkoPoints ?? 0

export const getKelpState = (state: AppState): number =>
	state.user.kelpPoints ?? 0

export const getEtherFiState = (state: AppState): number =>
	state.user.etherfiPoints ?? 0

export const getRenzoState = (state: AppState): number =>
	state.user.renzoPoints ?? 0

export const getSwellState = (state: AppState): number =>
	state.user.swellPoints ?? 0

export const getEigenlayerState = (state: AppState): number =>
	state.user.eigenlayerPoints ?? 0

export const {
	setIsConnectedState,
	setAddressState,
	setPreferredProtocolState
} = userSlice.actions

export default userSlice.reducer
