const KelpIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" fill="#fff">
			<g id="kelp">
				<path d="M313.5,35.1l-70.1,38.7c-25.3,14-27.6,48.8-4.3,65.8l71.5,52.2c14.6,10.6,13.2,32.4-2.7,41.1l-64.5,35.7c-25.3,14-27.6,48.9-4.2,65.8l68.1,49.5c7.1,5.1,11.2,13.2,11.2,21.8v2c0,6.3,6.9,10.2,12.5,7.1l70.4-39.2c25.3-14.1,27.4-49,3.9-65.9l-71.3-51.3c-14.7-10.5-13.4-32.4,2.4-41.2l65.3-36.4c25.3-14.1,27.4-49,3.9-65.9l-68.3-49.2c-7.1-5.1-11.3-13.3-11.3-21.9v-1.7c0-6.1-6.9-10.1-12.5-7Z" />
				<path d="M159.9,96.6l-70.1,38.7c-25.3,14-27.6,48.8-4.3,65.8l71.5,52.2c14.6,10.6,13.2,32.4-2.7,41.1l-64.5,35.7c-25.3,14-27.6,48.9-4.2,65.8l68.1,49.5c7.1,5.1,11.2,13.2,11.2,21.8v2c0,6.3,6.9,10.2,12.5,7.1l70.4-39.2c25.3-14.1,27.4-49,3.9-65.9l-71.5-51.4c-14.7-10.5-13.4-32.4,2.4-41.2l65.3-36.4c25.3-14.1,27.4-49,3.9-65.9l-68.3-49.2c-7.1-5.1-11.3-13.3-11.3-21.9v-1.7c.1-6.1-6.8-10-12.3-6.9Z" />
			</g>
		</svg>
	)
}

export default KelpIcon
