const GithubIcon = () => {
	return (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M12.3034 0C5.50986 0 0 5.50883 0 12.3034C0 17.7394 3.52491 22.3511 8.41448 23.9782C9.02862 24.092 9.22752 23.7106 9.22752 23.3866V21.0962C5.80514 21.8405 5.09257 19.6444 5.09257 19.6444C4.53276 18.2223 3.72587 17.844 3.72587 17.844C2.60934 17.0801 3.81097 17.0965 3.81097 17.0965C5.04643 17.1827 5.69646 18.3648 5.69646 18.3648C6.79351 20.2452 8.57442 19.7018 9.27674 19.387C9.38644 18.5924 9.7053 18.049 10.058 17.7425C7.32563 17.4298 4.45279 16.3748 4.45279 11.6615C4.45279 10.3174 4.93365 9.22035 5.72004 8.35911C5.5929 8.04845 5.17151 6.79658 5.84 5.10282C5.84 5.10282 6.87348 4.77268 9.22445 6.36391C10.2056 6.09119 11.2576 5.95483 12.3034 5.9497C13.3492 5.95483 14.4021 6.09119 15.3854 6.36391C17.7343 4.77268 18.7657 5.10282 18.7657 5.10282C19.4352 6.79761 19.0138 8.04948 18.8867 8.35911C19.6762 9.22035 20.1529 10.3184 20.1529 11.6615C20.1529 16.3871 17.2749 17.4277 14.5354 17.7322C14.9763 18.1136 15.3792 18.8621 15.3792 20.0104V23.3866C15.3792 23.7137 15.5761 24.0982 16.2005 23.9772C21.0859 22.348 24.6067 17.7374 24.6067 12.3034C24.6067 5.50883 19.0979 0 12.3034 0Z" />
		</svg>
	)
}

export default GithubIcon
