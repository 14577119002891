import RebaseListDtoModel from '@models/dto/RebaseListDtoModel'
import StatsDtoModel from '@models/dto/StatsDtoModel'
import TransactionDtoModel from '@models/dto/TransactionDtoModel'
import backendClient from '@network/BackendClient'
import SubgraphClient from '@network/SubgraphClient'
import { Hex } from 'viem'

export const getTotals = async (): Promise<RebaseListDtoModel> => {
	const data = {
		query: `{
			rebases(orderBy: blockNumber, orderDirection: desc) {
				totalEthLocked
				totalAkkEthShares
			}
		}`
	}

	return SubgraphClient.post('', data)
}

export const getInfo = async (): Promise<StatsDtoModel> => {
	return backendClient.get('/info')
}

export const getTransactions = async (
	address: Hex
): Promise<TransactionDtoModel> => {
	return backendClient.get(`/txdata/${address}`)
}
