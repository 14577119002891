const WalletConnectIcon = () => {
	return (
		<svg
			width="50"
			height="31"
			viewBox="0 0 50 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.2361 5.98752C18.39 -1.99584 31.61 -1.99584 39.764 5.98752L40.7453 6.94831C41.153 7.34752 41.153 7.99466 40.7453 8.39388L37.3884 11.6806C37.1845 11.8802 36.854 11.8802 36.6501 11.6806L35.2997 10.3585C29.6113 4.78901 20.3887 4.78901 14.7003 10.3585L13.254 11.7743C13.0503 11.974 12.7198 11.974 12.5159 11.7743L9.15898 8.48763C8.75117 8.08841 8.75117 7.44127 9.15898 7.04206L10.2361 5.98752ZM46.7065 12.7849L49.6942 15.71C50.1019 16.1092 50.1019 16.7564 49.6942 17.1556L36.2225 30.3458C35.8148 30.7449 35.1538 30.7449 34.7461 30.3458L25.1846 20.9844C25.0827 20.8845 24.9174 20.8845 24.8155 20.9844L15.2543 30.3458C14.8466 30.7449 14.1855 30.7449 13.7779 30.3458L0.305771 17.1555C-0.101924 16.7562 -0.101924 16.1091 0.305771 15.7099L3.29348 12.7846C3.70117 12.3855 4.36219 12.3855 4.76988 12.7846L14.3315 22.1462C14.4333 22.2461 14.5986 22.2461 14.7005 22.1462L24.2616 12.7846C24.6693 12.3855 25.3303 12.3855 25.738 12.7846L35.2996 22.1462C35.4015 22.2461 35.5668 22.2461 35.6687 22.1462L45.23 12.7849C45.6379 12.3857 46.2988 12.3857 46.7065 12.7849Z"
				fill="#3396FF"
			/>
		</svg>
	)
}

export default WalletConnectIcon
