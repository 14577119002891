import DaiIcon from './icons/chainIcons/DaiIcon'
import EthIcon from './icons/chainIcons/EthIcon'
import UsdCIcon from './icons/chainIcons/UsdCIcon'
import UsdTIcon from './icons/chainIcons/UsdTIcon'
import WbtcIcon from './icons/chainIcons/WbtcIcon'
import AkkoIcon from './icons/vaultIcons/AkkoIcon'
import EigenlayerIcon from './icons/vaultIcons/EigenlayerIcon'
import EtherfiIcon from './icons/vaultIcons/EtherfiIcon'
import KelpIcon from './icons/vaultIcons/KelpIcon'
import RenzoIcon from './icons/vaultIcons/RenzoIcon'
import SwellIcon from './icons/vaultIcons/SwellIcon'
import CoinbaseIcon from './icons/walletIcons/CoinbaseIcon'
import MetaMaskIcon from './icons/walletIcons/MetaMaskIcon'
import WalletConnecIcon from './icons/walletIcons/WalletConnecIcon'

interface Props {
	name: string
}
const IconSelector = ({ name }: Props) => {
	const selectIcon = (name: string): JSX.Element => {
		switch (name) {
			case 'ETH':
				return <EthIcon />
			case 'USDC':
				return <UsdCIcon />
			case 'USDT':
				return <UsdTIcon />
			case 'DAI':
				return <DaiIcon />
			case 'WBTC':
				return <WbtcIcon />
			case 'MetaMask':
				return <MetaMaskIcon />
			case 'Coinbase Wallet':
				return <CoinbaseIcon />
			case 'WalletConnect':
				return <WalletConnecIcon />
			case 'Loyalty Points':
				return <EtherfiIcon />
			case 'Kelp Miles':
				return <KelpIcon />
			case 'Eigenlayer Points':
				return <EigenlayerIcon />
			case 'Pearls':
				return <SwellIcon />
			case 'ezPoints':
				return <RenzoIcon />
			default:
				return <AkkoIcon />
		}
	}

	return selectIcon(name)
}

export default IconSelector
