const ArrowDropdownIcon = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="16"
			height="10"
			viewBox="0 0 16 10"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M0.0636303 2.04278L7.11142 9.09058C7.11142 9.09058 8.00011 10.2181 9.08659 8.97489L15.6327 2.32732C15.9089 2.0464 16.0548 1.64937 15.9809 1.26208C15.9316 1.00219 15.7862 0.742711 15.4373 0.599498C14.566 0.24192 14.0432 0.814085 14.0432 0.814085L8.03297 6.84499L1.98032 0.814085C1.98032 0.814085 1.26394 0.144047 0.566771 0.599498C-0.130397 1.05497 -0.0237894 1.95521 0.0633657 2.04255" />
		</svg>
	)
}

export default ArrowDropdownIcon
