const SwellIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" fill="#fff">
			<g id="swell">
				<path d="M447.13,245.17c-5.4-2.27-10.32-4.52-15.36-6.43-12.25-4.64-24.93-7.29-38.01-8.32-29.49-2.31-57.34,4.3-84.5,14.97-23.58,9.26-45.31,21.96-66.59,35.55-25.54,16.31-52.26,30.25-81.53,38.74-16.29,4.72-32.89,6.91-49.88,6.11-24.54-1.16-40.48-14.25-50.25-35.98-4.42-9.84-7.25-20.12-8.91-31.28,1.31.52,2.29.86,3.22,1.28,17.2,7.74,35.25,9.67,53.83,7.88,32.58-3.15,61.87-15.89,89.94-31.69,17.42-9.8,34.13-20.86,51.52-30.72,23.93-13.57,49.16-24.06,76.29-29.41,27.8-5.49,55.27-4.83,82.03,5.4,14.68,5.61,26.82,14.35,33.5,29.19,4.92,10.92,5.05,22.52,4.7,34.7Z" />
				<path d="M429.92,291.7c-.71.8-1.28,1.53-1.93,2.19-45,44.97-90.02,89.92-135.02,134.89-24.48,24.46-62.62,24.45-87.15-.05-11.93-11.91-23.86-23.82-35.78-35.74-5.69-5.69-4.45-10.74,3.29-13.17,26.15-8.18,51.83-17.58,75.94-30.69,13.44-7.31,26.29-15.7,39.54-23.38,24.91-14.43,50.95-26.14,79.23-32.37,20.13-4.43,40.36-5.63,60.77-2.28.29.05.54.29,1.11.6Z" />
				<path d="M66.93,207.73c1.08-.74,2.31-1.33,3.22-2.24,34.28-34.18,68.54-68.39,102.79-102.61,1.43-1.43,2.51-2.98,5.12-1.88,1.44.6,3.54.3,5.13-.23,5.21-1.75,10.01.11,14.98,1.09,1.92.38,4.05-.26,6.09-.45,3.56-.33,7.13-.68,10.69-1,.64-.06,1.4-.21,1.93.04,5.92,2.77,11.86.47,17.8.27,2.79-.09,5.6,1.02,8.42,1.07,2.66.04,5.33-.68,8.01-.91,2.41-.2,4.88-.5,7.25-.21,3.58.44,6.75,2.63,10.81.35,2.18-1.23,6.01-.91,8.48.14,3.22,1.37,6.14.26,9.17.43,1.41.08,2.89-.97,4.28-.84,2.53.24,4.99,1.23,7.51,1.46,2.29.21,4.63-.15,6.94-.3,3.47-.22,6.93-.55,10.4-.67,1.83-.06,3.68.4,5.5.33,3.61-.13,7.68,3.24,8.06,6.86.41,3.83-1.36,6.11-5.5,7.14-15.27,3.8-30.67,7.15-45.79,11.5-29.62,8.51-57.47,20.96-83.44,37.79-24.88,16.13-51.16,29.59-79.84,37.85-14.36,4.13-28.96,6.69-43.97,6.27-1.07-.03-2.14,0-3.2,0-.28-.41-.57-.83-.85-1.24Z" />
			</g>
		</svg>
	)
}

export default SwellIcon
