const MediumIcon = () => {
	return (
		<svg
			width="43"
			height="24"
			viewBox="0 0 43 24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M23.8364 11.9996C23.8364 18.6273 18.5005 24 11.9182 24C5.3359 24 0 18.629 0 11.9996C0 5.37017 5.3359 0 11.9182 0C18.5005 0 23.8364 5.37274 23.8364 11.9996Z" />
			<path d="M36.9112 11.9995C36.9112 18.2383 34.2429 23.2958 30.9517 23.2958C27.6606 23.2958 24.9922 18.2366 24.9922 11.9995C24.9922 5.76246 27.6614 0.704102 30.9526 0.704102C34.2437 0.704102 36.9121 5.76332 36.9121 12.0004" />
			<path d="M42.2587 11.9994C42.2587 17.5889 41.3199 22.1204 40.1626 22.1204C39.0053 22.1204 38.0664 17.588 38.0664 11.9994C38.0664 6.41081 39.0053 1.87842 40.1626 1.87842C41.3199 1.87842 42.2579 6.40995 42.2579 11.9994" />
		</svg>
	)
}

export default MediumIcon
