const EigenlayerIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" fill="#fff">
			<g id="eigenlayer">
				<path d="M97.56,433.99V75.87c.71.01,1.41.03,2.12.03,28.4,0,56.79,0,85.19,0,.68,0,1.36,0,2.21,0v268.54h44.86v-89.31c.54-.04.87-.08,1.19-.08,14.09,0,28.18,0,42.27,0,.48,0,.97.1,1.45.16,0,0-.04-.05-.04-.05-.07.64-.19,1.28-.19,1.92,0,28.45,0,56.9,0,85.35v2.14h89.13v89.39h-2.35c-87.85,0-175.69,0-263.54,0-.76,0-1.52,0-2.28.01Z" />
				<path d="M366.12,165.25v89.58c-.79.04-1.53.1-2.27.1-28.33,0-56.66,0-84.98.01-.69,0-1.38.14-2.06.21,0,0,.04.05.04.05-.04-.81-.13-1.62-.13-2.44,0-43.25,0-86.49,0-129.74,0-.75,0-1.5,0-2.43h-42.93v-44.6h87.57v89.28c.6.05,1.01.12,1.43.12,14.03,0,28.07,0,42.1,0,.43,0,.86-.07,1.29-.1l-.06-.05Z" />
				<rect x="365.73" y="75.54" width="45.51" height="89.79" />
			</g>
		</svg>
	)
}

export default EigenlayerIcon
