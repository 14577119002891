import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import RebaseDtoModel from '@models/dto/RebaseDtoModel'
import CompositionDtoModel from '@models/dto/StatsDtoModel'
import { getInfo, getTotals } from '@services/ExchangeRateService'
import BigNumber from 'bignumber.js'
import { AppState } from './Store'

export interface ExchangeState {
	exchangePending: boolean
	exchangeError: boolean
	exchangeRate?: number
	tvl?: number
	apr?: number
	rate?: number
	akkoPointsTotal?: number
	restakingPoints?: number

	composition?: CompositionDtoModel[]
}

const initialState: ExchangeState = {
	exchangePending: false,
	exchangeError: false
}

export const exchangeSlice = createSlice({
	name: 'exchange',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getExchangeData.pending, (state) => {
				state.exchangePending = true
			})
			.addCase(getExchangeData.fulfilled, (state, { payload }) => {
				const rate = Number(
					new BigNumber(Number(payload?.totalAkkEthShares ?? 1)).div(
						1000000000000000000
					) ?? 0
				)
				const tvl = Number(
					new BigNumber(Number(payload?.totalEthLocked ?? 1)).div(
						1000000000000000000
					) ?? 0
				)

				state.exchangePending = false
				state.exchangeError = false
				state.exchangeRate = rate / tvl
				state.tvl = tvl
			})
			.addCase(getExchangeData.rejected, (state) => {
				state.exchangePending = false
				state.exchangeError = true
			})

			// Info
			.addCase(getExchangeInfoData.pending, (state) => {
				state.exchangePending = true
			})
			.addCase(getExchangeInfoData.fulfilled, (state, { payload }) => {
				state.exchangePending = false
				state.exchangeError = false

				state.apr = payload.apr
				state.restakingPoints = payload.restakingPoints
				state.akkoPointsTotal = payload.akkoPoints_total
				state.composition = payload.composition
				state.rate = payload.rate
			})
			.addCase(getExchangeInfoData.rejected, (state) => {
				state.exchangePending = false
				state.exchangeError = true
			})
	}
})

export const getExchangeData = createAsyncThunk(
	'exchange/data',
	async (): Promise<RebaseDtoModel | undefined> =>
		(await getTotals()).rebases[0]
)

export const getExchangeInfoData = createAsyncThunk(
	'exchange/info',
	async () => await getInfo()
)

export const getExchangeRateState = (state: AppState): number | undefined =>
	state.exchange?.exchangeRate

export const getTvlState = (state: AppState): number | undefined =>
	state.exchange?.tvl

export const getAprState = (state: AppState): number | undefined =>
	state.exchange.apr

export const getRestakingState = (state: AppState): number | undefined =>
	state.exchange.restakingPoints

export const getAkkoTotalState = (state: AppState): number =>
	state.exchange.akkoPointsTotal ?? 0

export const getRateState = (state: AppState): number | undefined =>
	state.exchange.rate

export const getCompositionState = (
	state: AppState
): CompositionDtoModel[] | undefined => state.exchange.composition

export default exchangeSlice.reducer
