import { Dispatch, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'

export interface CustomMiddlewareAPI extends MiddlewareAPI {
	blockList?: string[]
}

export const localStorageMiddleware: Middleware<Dispatch> =
	({ getState, blockList = [] }: CustomMiddlewareAPI) =>
	(next) =>
	(action: any) => {
		if (typeof window === 'undefined') return

		const result = next(action)

		if (action.type?.startsWith(blockList)) return result

		const tempState = { ...getState() }

		blockList.forEach((block) => {
			if (!block.includes('/')) {
				delete tempState[block]
				return
			}

			const items = block.split('/')

			if (tempState[items[0]][items[1]] === null) return

			const { [items[1]]: _, ...other } = tempState[items[0]]

			tempState[items[0]] = other
			return
		})

		localStorage.setItem('applicationState', JSON.stringify(tempState))
	}

export const reHydrateStore = () => {
	if (typeof window === 'undefined') return

	if (localStorage.getItem('applicationState') === null) return

	return JSON.parse(localStorage.getItem('applicationState') ?? '') // re-hydrate the store
}
