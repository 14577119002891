import axios, { AxiosError, AxiosResponse } from 'axios'

// import StatsResults from '@services/get-stats.mock.json'
// import MockAdapter from 'axios-mock-adapter'

// const mock = new MockAdapter(axios, { delayResponse: 0 })

// mock.onGet('/stats').reply(200, StatsResults).onAny().passThrough()

const backendClient = axios.create({
	baseURL: process.env.NEXT_PUBLIC_PROXY
		? `${process.env.NEXT_PUBLIC_PROXY_URL}/backend`
		: process.env.NEXT_PUBLIC_BACKEND_URL,
	// timeout: 1500,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Methods': 'GET'
	}
})

backendClient.interceptors.response.use(
	({ data }: AxiosResponse) => {
		if (data.data) return data.data
		return data
	},
	(error: AxiosError) => {
		console.log('Interceptor', 'response', 'error', error)
		Promise.reject(error)
	}
)

export default backendClient
