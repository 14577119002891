import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppState } from '@store/Store'

export interface SettingsState {
	isDarkMode: boolean
	isLargeMode: boolean
	isConnectModalOpen?: boolean
	isCampaignModalOpen?: boolean
}

const initialState: SettingsState = {
	isDarkMode: true,
	isLargeMode: false
}

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setDarkModeState(state, { payload }: PayloadAction<boolean>) {
			state.isDarkMode = payload
		},
		setLargeModeState(state, { payload }: PayloadAction<boolean>) {
			state.isLargeMode = payload
		},
		setConnectModalOpenState(state, { payload }: PayloadAction<boolean>) {
			state.isConnectModalOpen = payload
			if (payload === true) {
				document.body.classList.add('modal-open')
			} else {
				document.body.classList.remove('modal-open')
			}
		},
		setCampaignModalOpenState(state, { payload }: PayloadAction<boolean>) {
			state.isCampaignModalOpen = payload
			if (payload === true) {
				document.body.classList.add('modal-open')
			} else {
				document.body.classList.remove('modal-open')
			}
		}
	}
})

export const isDarkModeState = (state: AppState): boolean =>
	state.settings?.isDarkMode

export const isLargeModeState = (state: AppState): boolean =>
	state.settings?.isLargeMode

export const isConnectModalOpenState = (state: AppState): boolean | undefined =>
	state.settings?.isConnectModalOpen

export const isCampaignModalOpenState = (
	state: AppState
): boolean | undefined => state.settings?.isCampaignModalOpen

export const {
	setDarkModeState,
	setLargeModeState,
	setConnectModalOpenState,
	setCampaignModalOpenState
} = settingsSlice.actions

export default settingsSlice.reducer
