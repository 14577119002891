const CopyIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24">
			<path d="m19,6h-1v-1c0-2.76-2.24-5-5-5h-8C2.24,0,0,2.24,0,5v8c0,2.76,2.24,5,5,5h1v1c0,2.76,2.24,5,5,5h8c2.76,0,5-2.24,5-5v-8c0-2.76-2.24-5-5-5Zm-13,5v5.5h-1c-1.93,0-3.5-1.57-3.5-3.5V5c0-1.93,1.57-3.5,3.5-3.5h8c1.93,0,3.5,1.57,3.5,3.5v1h-5.5c-2.76,0-5,2.24-5,5Zm16.5,8c0,1.93-1.57,3.5-3.5,3.5h-8c-1.93,0-3.5-1.57-3.5-3.5v-8c0-1.93,1.57-3.5,3.5-3.5h8c1.93,0,3.5,1.57,3.5,3.5v8Z" />
		</svg>
	)
}

export default CopyIcon
