import { useAppSelector } from '@hooks/ReduxStore'
import { isDarkModeState, isLargeModeState } from '@store/SettingsSlice'
import {
	darkTheme,
	largeDarkTheme,
	largeLightTheme,
	lightTheme
} from '@theme/ThemeConfig'
import { ReactNode, useLayoutEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

interface Props {
	children: JSX.Element | JSX.Element[] | ReactNode
}

const ThemeWrapper = ({ children }: Props) => {
	const darkModeState = useAppSelector<boolean>(isDarkModeState)
	const largeModeState = useAppSelector<boolean>(isLargeModeState)

	const [isDarkMode, setIsDarkMode] = useState<boolean>(false)
	const [isLargeMode, setIsLargeMode] = useState<boolean>(false)

	useLayoutEffect(() => {
		setIsDarkMode(darkModeState)
	}, [darkModeState])

	useLayoutEffect(() => {
		setIsLargeMode(largeModeState)
	}, [largeModeState])

	return (
		<ThemeProvider
			theme={
				isDarkMode
					? isLargeMode
						? largeDarkTheme
						: darkTheme
					: isLargeMode
					  ? largeLightTheme
					  : lightTheme
			}>
			{children}
		</ThemeProvider>
	)
}

export default ThemeWrapper
