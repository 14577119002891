const AkkoIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" fill="#fff">
			<g id="akko">
				<path d="M46.61,394.61c-2.84-2.87-5.01-5.81-6.51-8.8s-2.54-5.83-3.11-8.51-.4-5.77.48-9.32c1.02-4.09,3.23-6.15,6.65-6.15,1.36,0,2.7.42,3.99,1.24l6.45,3.69c3.01,1.64,6.91,2.46,11.68,2.46,9.02,0,20.54-6.55,34.62-19.67,14.77-13.8,26.55-29.77,35.32-47.94-30.95-12.3-52.51-25-64.67-38.11-6.09-6.83-10.06-13.9-11.92-21.2-1.86-7.31-1.72-15.33.48-24.08,2.18-8.74,7.21-15.49,15.09-20.28,3.59-2.32,7.53-4.03,11.82-5.13s3.25-1.14,9.26-1.02c2.34.06,4.49.6,8.13,3.05,3.61,2.46,5.55,5.01,5.77,7.69.06.62.12,1.2.18,1.72.28,2.2.28,4.43-.1,6.63-.16.98-.38,2.1-.62,3.23-.34,1.6-.8,3.37-1.86,7.61-2.08,8.33.48,17.29,7.65,26.83,11.9,15.85,32.12,30.05,60.67,42.63-.66-2.87-1.42-7.45-2.32-13.74l-6.03-53.07c-1.34-12.16-2.89-32.5-4.65-61.05-.56-10.92-.88-21.4-.98-31.45-.1-10.04.14-19.29.68-27.77.54-8.47,1.52-16.09,2.93-22.84s6.55-13.08,15.37-18.95c4.57-2.99,9.72-4.51,15.47-4.51,9.7,0,13.88,5.73,12.52,17.21-2.52,23.76-3.65,50.53-3.43,80.32.64,80.72,7.17,147.38,19.61,199.97l4.05,16.59c1.38,6.01,1.8,10.04,1.3,12.08-1.3,5.19-4.87,9.96-10.74,14.34-2.84,2.06-5.99,3.71-9.44,5.01-3.45,1.3-7.11,1.94-10.94,1.94s-6.75-2.12-8.76-6.35-3.85-10.18-5.49-17.83l-5.35-27.87c-2.06-10.92-5.03-23.08-8.94-36.48-33.12,58.88-65.19,88.31-96.19,88.31-11.62,0-20.98-3.47-28.13-10.44l.02.02Z" />
				<path d="M406.07,405.05c-31.01,0-63.07-29.43-96.19-88.31-3.89,13.4-6.89,25.54-8.94,36.48l-5.35,27.87c-1.64,7.65-3.47,13.6-5.49,17.83-2.02,4.23-4.93,6.35-8.76,6.35s-7.47-.64-10.94-1.94-6.61-2.97-9.44-5.01c-5.87-4.37-9.44-9.14-10.74-14.34-.52-2.06-.08-6.07,1.3-12.08l4.05-16.59c12.44-52.59,18.97-119.25,19.61-199.97.22-29.77-.92-56.54-3.43-80.32-1.36-11.48,2.82-17.21,12.52-17.21,5.73,0,10.9,1.5,15.47,4.51,8.84,5.87,13.98,12.2,15.37,18.95,1.42,6.77,2.4,14.37,2.93,22.84.54,8.47.76,17.73.68,27.77s-.42,20.52-.98,31.45c-1.76,28.55-3.31,48.89-4.65,61.05l-6.03,53.07c-.9,6.29-1.66,10.86-2.32,13.74,28.55-12.56,48.78-26.77,60.67-42.63,7.19-9.56,9.72-18.51,7.65-26.83-1.06-4.23-1.52-6.01-1.86-7.61-.24-1.14-.44-2.26-.62-3.23-.38-2.2-.38-4.41-.1-6.63.06-.52.12-1.1.18-1.72.22-2.66,2.16-5.23,5.77-7.69,3.61-2.46,5.79-3.01,8.13-3.05,6.01-.14,4.97-.08,9.26,1.02,4.31,1.1,8.25,2.8,11.82,5.13,7.89,4.79,12.92,11.54,15.09,20.28,2.18,8.74,2.34,16.77.48,24.08-1.86,7.31-5.85,14.37-11.92,21.2-12.16,13.12-33.72,25.82-64.67,38.11,8.76,18.17,20.52,34.14,35.32,47.94,14.06,13.12,25.6,19.67,34.62,19.67,4.77,0,8.66-.82,11.68-2.46l6.45-3.69c1.3-.82,2.64-1.24,3.99-1.24,3.41,0,5.63,2.06,6.65,6.15.88,3.55,1.04,6.67.48,9.32s-1.6,5.51-3.11,8.51c-1.5,3.01-3.67,5.95-6.51,8.8-7.15,6.97-16.51,10.44-28.13,10.44l.02-.02Z" />
			</g>
		</svg>
	)
}

export default AkkoIcon
