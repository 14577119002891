import AkkorokamuiIcon from '@components/icons/AkkorokamuiIcon'
import { useAppDispatch, useAppSelector } from '@hooks/ReduxStore'
import {
	isConnectModalOpenState,
	setConnectModalOpenState
} from '@store/SettingsSlice'
import { isConnectedState } from '@store/UserSlice'
import { useCallback, useLayoutEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Connector, useConnect } from 'wagmi'
import ConnectorButton from './ConnectorButton'
import Modal from './Modal'

const ConnectWalletModal = () => {
	const dispatch = useAppDispatch()
	const connectedState = useAppSelector<boolean>(isConnectedState)
	const openModalState = useAppSelector<boolean | undefined>(
		isConnectModalOpenState
	)

	const [isConnected, setIsConnected] = useState<boolean>(false)
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
	const [connectorList, setConnectorList] = useState<Connector[]>([])

	const { connectors } = useConnect()

	useLayoutEffect(() => {
		setIsConnected(connectedState)
	}, [connectedState])

	useLayoutEffect(() => {
		setIsOpenModal(openModalState ?? false)
	}, [openModalState])

	useLayoutEffect(() => {
		setConnectorList([...connectors])
	}, [connectors])

	const closeModal = useCallback((): void => {
		dispatch(setConnectModalOpenState(false))
	}, [dispatch])

	return (
		<Modal closeModal={closeModal} isOpen={isOpenModal}>
			<>
				<Header>
					<LogoBox>
						<AkkorokamuiIcon />
					</LogoBox>
					<h4>Connect Wallet</h4>
					<p>to start using Akko</p>
					<p>{isConnected ? 'Connected' : 'Not connected'}</p>
				</Header>
				<Content>
					{connectorList.map((connector, index) => (
						<ConnectorButton
							isConnected={isConnected}
							connector={connector}
							key={index}
						/>
					))}
				</Content>
			</>
		</Modal>
	)
}

const Header = styled.div`
	text-align: center;

	> p {
		font-family: ${({ theme }) => theme.fonts.robotoLight};
		color: inherit;
	}
`
const Content = styled.div`
	text-align: center;

	> button:last-child {
		border-bottom: none;
	}
`
const LogoBox = styled.div`
	width: 8em;
	height: 8em;
	margin: 1em auto;
	padding: 1em;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.style.colorText};
`
export default ConnectWalletModal
