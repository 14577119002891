const EyeClosedIcon = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path d="m1.85,1.15c-.2-.2-.51-.2-.71,0-.2.2-.2.51,0,.71l3.5,3.5c-1.67,1.14-2.92,2.92-3.37,5.05-.06.27.12.54.39.59s.54-.12.59-.39c.4-1.94,1.56-3.54,3.11-4.53l1.59,1.59c-.88.64-1.45,1.67-1.45,2.84,0,1.93,1.57,3.5,3.5,3.5,1.17,0,2.2-.57,2.84-1.45l4.31,4.31c.2.2.51.2.71,0,.2-.2.2-.51,0-.71L1.85,1.15Zm9.27,10.68c-.44.7-1.23,1.17-2.12,1.17-1.38,0-2.5-1.12-2.5-2.5,0-.89.47-1.68,1.17-2.12l3.45,3.45Z" />
			<path d="m9.08,6.96l3.37,3.37c-.06-1.83-1.54-3.31-3.37-3.37Z" />
			<path d="m9.04,5.04c-.57,0-1.13.07-1.67.21l-.8-.8c.78-.27,1.61-.41,2.47-.41,3.69,0,6.94,2.67,7.72,6.4.06.27-.12.54-.39.59-.27.06-.54-.12-.59-.39-.68-3.28-3.54-5.6-6.74-5.6Z" />
		</svg>
	)
}

export default EyeClosedIcon
