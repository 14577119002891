const Logo = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 368.44 166.46"
			fill="currentColor">
			<path d="M85.13,148.4h-.02c-4.24,0-6.79-3.02-7.66-9.03-.19-1.31-.3-2.55-.34-3.73-.73-8.24,4.69-29.17,16.26-62.8.76-1.96-.85-4.25-4.82-6.87-2.1-1.37-5.01-2.06-8.73-2.06-3.47,0-6.93,3.63-10.4,10.89-5.89-7.26-12.79-10.89-20.71-10.89s-15.94,3.37-24.06,10.11c-8.14,6.61-14.44,15.14-18.88,25.61C.91,110.82-.88,122.24.41,133.88c.95,9.29,3.99,17.01,9.13,23.16,2.52,2.95,5.38,5.25,8.6,6.92,3.22,1.67,6.86,2.5,10.91,2.5s7.75-.92,11.08-2.75c8.35-4.71,14.67-13.28,18.96-25.71-.1,3.79.09,7.39.58,10.79.31,2.1,1.13,4.42,2.48,6.97,2.74,4.97,6.37,8.16,10.82,9.71.03.02.07.02.11.02,2.2,0,15.59-17.09,12.05-17.09ZM61.39,102.87c-4,16.29-9.42,29.24-16.28,38.86-4.68,6.54-8.95,9.81-12.81,9.81h-.02c-5.03.01-8.12-3.92-9.25-11.77-1.43-9.95-.88-19.96,1.66-30.03,1.21-4.71,2.79-9.08,4.73-13.1,1.94-4.02,4.15-7.51,6.64-10.45,2.48-2.95,5.16-5.27,8.02-6.97,2.86-1.7,5.45-2.55,7.78-2.55s4.13.36,5.41,1.08c3.77,2.09,6.11,5.36,7.01,9.81-.63,5.1-1.6,10.21-2.89,15.31Z" />
			<path d="M184.36,150.47l-1.94-7.95c-5.96-25.19-9.09-57.12-9.39-95.78-.11-14.26.44-27.09,1.64-38.47.65-5.49-1.35-8.24-5.99-8.24v.03c-2.75,0-5.22.72-7.41,2.16-4.24,2.82-6.7,5.84-7.37,9.08-.67,3.24-1.14,6.88-1.4,10.94s-.36,8.49-.32,13.3.19,9.83.46,15.06c.85,13.67,1.59,23.42,2.23,29.24l2.89,25.42c.43,3,.8,5.2,1.11,6.57-13.68-6.02-23.36-12.82-29.06-20.41-3.43-4.58-4.65-8.87-3.66-12.86.51-2.03.73-2.88.89-3.64.11-.55.21-1.08.29-1.55.18-1.05.18-2.11.05-3.17-.04-.25-.07-.52-.09-.82-.1-1.28-1.02-2.5-2.76-3.68-1.74-1.18-2.77-1.45-3.89-1.47-.67-.02-1.15-.02-1.53-.02-1.25,0-1.33.11-2.91.51-2.07.52-3.95,1.34-5.67,2.45-3.78,2.29-6.19,5.52-7.23,9.71-1.05,4.19-1.12,8.03-.23,11.53.89,3.5,2.8,6.89,5.71,10.16,5.82,6.28,16.15,12.36,30.97,18.25-4.2,8.7-9.83,16.35-16.91,22.96-6.74,6.28-12.26,9.42-16.58,9.42-2.29,0-4.15-.39-5.59-1.18l-3.09-1.77c-.62-.39-1.26-.59-1.91-.59-1.64,0-2.7.98-3.19,2.94-.42,1.7-.5,3.18-.23,4.46.27,1.27.77,2.63,1.49,4.07.72,1.44,1.76,2.85,3.12,4.22,3.42,3.33,7.91,5,13.47,5,14.85,0,30.21-14.09,46.07-42.29,1.87,6.42,3.3,12.24,4.28,17.47l2.56,13.35c.79,3.67,1.66,6.51,2.63,8.54.97,2.03,2.37,3.04,4.2,3.04s3.59-.31,5.24-.93c1.65-.62,3.16-1.42,4.52-2.4,2.81-2.09,4.53-4.38,5.15-6.87.24-.98.04-2.91-.62-5.79Z" />
			<path d="M210.67.03c2.75,0,5.22.72,7.41,2.16,4.23,2.81,6.69,5.84,7.37,9.08s1.14,6.89,1.4,10.94c.26,4.06.37,8.49.32,13.3-.04,4.81-.2,9.83-.46,15.06-.84,13.67-1.59,23.42-2.23,29.24l-2.89,25.42c-.43,3.01-.8,5.2-1.11,6.57,13.68-6.02,23.36-12.82,29.06-20.41,3.44-4.58,4.66-8.86,3.66-12.86-.51-2.03-.73-2.88-.89-3.64-.12-.55-.21-1.08-.29-1.55-.18-1.05-.18-2.12-.05-3.17.03-.25.06-.52.09-.82.11-1.28,1.03-2.5,2.76-3.68s2.77-1.44,3.89-1.47c.66-.01,1.15-.02,1.53-.02,1.26,0,1.33.11,2.91.51,2.06.52,3.95,1.34,5.67,2.45,3.78,2.29,6.19,5.53,7.23,9.71,1.04,4.19,1.12,8.03.23,11.53-.89,3.5-2.8,6.89-5.71,10.16-5.83,6.28-16.15,12.36-30.97,18.25,4.2,8.7,9.83,16.36,16.91,22.96,6.73,6.28,12.26,9.42,16.58,9.42,2.29,0,4.15-.39,5.59-1.18l3.09-1.77c.62-.39,1.26-.59,1.91-.59,1.64,0,2.7.98,3.19,2.94.42,1.7.5,3.19.23,4.46s-.77,2.63-1.49,4.07-1.76,2.85-3.12,4.22c-3.42,3.34-7.91,5-13.47,5-14.85,0-30.21-14.1-46.07-42.29-1.87,6.41-3.3,12.23-4.28,17.47l-2.56,13.35c-.79,3.66-1.66,6.51-2.63,8.54s-2.37,3.04-4.2,3.04-3.58-.31-5.24-.93-3.17-1.42-4.52-2.4c-2.81-2.09-4.53-4.38-5.15-6.87-.24-.98-.04-2.91.62-5.79l1.94-7.95c5.95-25.19,9.08-57.11,9.39-95.78.11-14.26-.44-27.08-1.64-38.47-.65-5.5,1.35-8.24,5.99-8.24" />
			<path d="M368.4,117.88c-.2-5.63-1.03-11.06-2.49-16.29-1.47-5.23-3.52-10.14-6.17-14.72-2.65-4.58-5.8-8.57-9.45-11.97-7.98-7.33-17.11-10.99-27.38-10.99-13.48,0-23.88,5.92-31.19,17.76-5.24,8.57-7.66,18.94-7.26,31.11.35,11.18,3.19,21.45,8.51,30.81,5.26,9.36,12.4,15.99,21.44,19.92,4.55,1.96,9.24,2.94,14.04,2.94s9.22-.62,13.25-1.86c4.03-1.24,7.7-3.17,11.01-5.79s6.18-5.94,8.61-9.96c2.43-4.03,4.26-8.79,5.51-14.28s1.77-11.05,1.57-16.68ZM344.15,140.59c-1.04,4.55-2.5,8.12-4.4,10.7-1.9,2.59-4.35,3.88-7.36,3.88h0,0c-3.01,0-5.75-1.03-8.22-3.09-2.47-2.06-4.71-4.79-6.73-8.19-2.02-3.4-3.76-7.33-5.23-11.78-1.47-4.44-2.6-9.09-3.39-13.93-1.73-10.54-1.65-19.89.23-28.07,1.54-7.2,4-11.78,7.36-13.74,1.35-.79,3.08-1.18,5.21-1.18s4.36.96,6.69,2.89,4.52,4.53,6.57,7.8,3.88,7.1,5.49,11.48c1.61,4.39,2.88,9,3.8,13.84.92,4.85,1.4,9.82,1.46,14.92.06,5.1-.44,9.92-1.48,14.47Z" />
		</svg>
	)
}
export default Logo
