const EyeOpenIcon = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path d="m16.72,10.39c-.6-2.86-2.66-5.1-5.26-5.98h0c-1.58-.54-3.35-.54-4.93,0h0c-2.6.89-4.66,3.12-5.26,5.99-.06.27.12.54.39.59.27.06.54-.12.59-.39,1.64-7.4,11.85-7.4,13.49,0,.13.64,1.11.44.98-.2Z" />
			<path d="m8.91,6.91c-1.96,0-3.55,1.59-3.55,3.55s1.59,3.55,3.55,3.55,3.55-1.59,3.55-3.55-1.59-3.55-3.55-3.55Zm0,6.02c-1.37,0-2.48-1.11-2.48-2.48s1.11-2.48,2.48-2.48,2.48,1.11,2.48,2.48-1.11,2.48-2.48,2.48Z" />
		</svg>
	)
}

export default EyeOpenIcon
