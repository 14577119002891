import ActionButton from '@components/buttons/ActionButton'
import LinkButton from '@components/buttons/LinkButton'
import SelectInputField from '@components/form/SelectInputField'
import AkkorokamuiIcon from '@components/icons/AkkorokamuiIcon'
import { useAppDispatch, useAppSelector } from '@hooks/ReduxStore'
import CompositionDtoModel from '@models/dto/StatsDtoModel'
import { SelectOptionModel } from '@models/internal/SelectOptionModel'
import { CampaignRequestModel } from '@models/requests/CampaignRequestModel'
import { getCompositionState } from '@store/ExchangeSlice'
import {
	isCampaignModalOpenState,
	setCampaignModalOpenState
} from '@store/SettingsSlice'
import { setPreferredProtocolState } from '@store/UserSlice'
import CampaignValidation from '@validations/CampaignValidation'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useCallback, useLayoutEffect, useState } from 'react'
import { styled } from 'styled-components'
import Modal from './Modal'

const CampaignModal = () => {
	const dispatch = useAppDispatch()

	const basketState = useAppSelector<CompositionDtoModel[] | undefined>(
		getCompositionState
	)
	const openModalState = useAppSelector<boolean | undefined>(
		isCampaignModalOpenState
	)

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
	const [basketList, setBasketList] = useState<CompositionDtoModel[]>([])

	useLayoutEffect(() => {
		setIsOpenModal(openModalState ?? false)
	}, [openModalState])
	useLayoutEffect(() => {
		setBasketList(basketState ?? [])
	}, [basketState])

	const initial: CampaignRequestModel = {
		ltr: ''
	}

	const closeModal = useCallback((): void => {
		dispatch(setCampaignModalOpenState(false))
	}, [dispatch])

	const onSubmit = useCallback(
		(
			form: CampaignRequestModel,
			formikHelpers: FormikHelpers<CampaignRequestModel>
		) => {
			dispatch(setPreferredProtocolState(Number(form.ltr)))
			formikHelpers.resetForm({ values: { ltr: '' } })
			closeModal()
		},
		[]
	)

	return (
		<Modal closeModal={closeModal} isOpen={isOpenModal ?? false}>
			<>
				<Header>
					<LogoBox>
						<AkkorokamuiIcon />
					</LogoBox>
					<h4>Pre-Deposit Campaign</h4>
					<p>
						Prepare thyself for Akko season 1, chapter 1 Follow us on Twitter,
						vote for your favorite LRT and deposit ETH to determine the future
						of the basket
					</p>
				</Header>
				<Content>
					<LinkButton
						href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Eakkoprotocol&region=follow_link&screen_name=akkoprotocol"
						target="_blank"
						$isCta
						$isBlock>
						Follow @akkoprotocol
					</LinkButton>

					<Formik
						initialValues={initial}
						validationSchema={CampaignValidation}
						enableReinitialize={true}
						validateOnMount={true}
						onSubmit={onSubmit}>
						{(formikProps: FormikProps<CampaignRequestModel>) => (
							<Form noValidate>
								<SelectInputField
									inputName="ltr"
									formikProps={formikProps}
									smallOptionList
									placeholder="Select favorite LTR"
									optionList={
										basketList?.map(
											({ name }, index) =>
												({
													name: name,
													value: index.toString()
												}) as SelectOptionModel
										) ?? []
									}
								/>
								<ActionButton
									$isBlock
									type="submit"
									$isCta
									disabled={
										!formikProps.isValid || formikProps.values.ltr === ''
									}>
									Continue Staking
								</ActionButton>
							</Form>
						)}
					</Formik>
				</Content>
			</>
		</Modal>
	)
}

const Header = styled.div`
	text-align: center;

	> p {
		font-family: ${({ theme }) => theme.fonts.robotoLight};
		color: inherit;
	}
`
const Content = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 1em;

	> button:last-child {
		border-bottom: none;
	}
`
const LogoBox = styled.div`
	width: 8em;
	height: 8em;
	margin: 1em auto;
	padding: 1em;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.style.colorText};
`

export default CampaignModal
